<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
let cookieDomain = process.env.VUE_APP_BASE_URL_LANDING || "advy.ai";
cookieDomain = cookieDomain.replace("https://", "");
Vue.use(VueCookies, { expires: "7d", path: "/", domain: cookieDomain });

import { mapActions } from "vuex";
import { Icon } from "@iconify/vue2";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseStepProgressBar from "@/components/BaseStepProgressBar.vue";

const TELEGRAM_LINK = "https://t.me/ADVY_Manager_Zakharbot";

export default {
  name: "CampaignForm",
  components: {
    BaseButton,
    BaseInput,
    BaseStepProgressBar,
    Icon,
  },
  data: () => ({
    currentStep: 1,
    steps: [1, 2, 3, 4],
    name: null,
    email: null,
    phone: null,
    formats: [],
    networks: [],
    format: null,
    network: null,
    campaignName: "",
    budget: ""
  }),
  created() {
    this.getCampaignFormatList();
  },
  methods: {
    ...mapActions({
      getCampaignFormats: "getCampaignFormats",
      createCampaign: "createCampaign",
    }),
    getCampaignFormatList() {
      this.getCampaignFormats().then((response) => {
        this.formats = response.data;
      });
    },
    goStepBack() {
      this.currentStep -= 1;
    },
    handleContinueBtnClick() {
      this.currentStep += 1;
    },
    selectFormat(item) {
      this.format = item;
    },
    selectNetwork(item) {
      this.network = item;
    },
    handleSubmit() {
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        budget: this.budget,
        kind: this.format.key,
        campaign_name: this.campaignName,
        network: this.network.network,
        currency: "USD",
      };

      this.createCampaign(data)
        .then((response) => {
          const tokens = response.data[0];
          const campaignData = response.data[1];

          const payload = JSON.stringify({
            access: tokens.access,
            refresh: tokens.refresh,
            campaign: campaignData.id,
          });

          this.$cookies.set("autologin", payload, {
            secure: true,
          });

          // const x = this.$cookies.get("autologin");

          window.location.href =
            (process.env.VUE_APP_BASE_URL_CABINET || "https://app.advy.ai") +
            "/campaign/" +
            campaignData.id +
            "/selection";
        })
        .catch((err) => {
          const data = err.response?.data;
          console.log(err);

          if (data.error == "user exists") {
            return alert(
              this.$gettext("Пользователь с таким email уже есть в системе")
            );
          }

          alert(this.$gettext("Возникла непредвиденная ошибка"));
        });
    },
    getNetworksInOrder(list) {
      return [...list].sort((a, b) => a.order - b.order);
    },
    getNetworkIcon(network) {
      if (network === "instagram") {
        return "bxl-instagram-alt";
      }

      return `bxl-${network}`;
    },
    getNetworkImage(network) {
      return require(`@/assets/${network}-favicon.png`);
    },
    getNetworkDescription(network) {
      if (network == "instagram") {
        return this.$gettext(`Only instagram oriented traffic`);
      } else if (network == "telegram") {
        return this.$gettext(`Only telegram oriented traffic`);
      } else if (network == "youtube") {
        return this.$gettext(`Only youtube oriented traffic`);
      } else if (network == "tiktok") {
        return this.$gettext(`Only tiktok oriented traffic`);
      }
    },
    showErrorModal() {
      this.$bvModal.show(this.errorModalId);
    },
    handleChallengeBtnClick() {
      window.open(TELEGRAM_LINK, "_blank");
    },
    validateEmail() {
      return String(this.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<template>
  <div class="card border-0" id="campaignForm">
    <div class="wrapper">
      <div class="wrapper-top">
        <div class="d-flex flex-column align-items-center gap-3">
          <h2 class="title">
            <translate>Create campaign</translate>
          </h2>

          <BaseStepProgressBar :currentStep="currentStep" :steps="steps" />
        </div>

        <div v-if="currentStep === 1" class="content">
          <span class="subtitle">
            <translate>Enter name, email and phone number:</translate>
          </span>

          <div class="inputs">
            <BaseInput
              v-model="name"
              :label="$gettext('User Name')"
              type="text"
            />

            <BaseInput
              v-model="email"
              :label="$gettext('Email')"
              type="email"
            />

            <div class="inputs-multiple">
              <BaseInput
                v-model="phone"
                :label="$gettext('Phone number')"
                type="number"
              />
            </div>
          </div>
        </div>

        <div v-if="currentStep === 2" class="content">
          <span class="subtitle">
            <translate>Add campaign name and maximum budget:</translate>
          </span>

          <div class="inputs">
            <BaseInput
              v-model="campaignName"
              :label="$gettext('Campaign name')"
            />

            <div class="inputs-multiple">
              <BaseInput
                v-model="budget"
                :label="$gettext('Maximum budget')"
                type="number"
              />
            </div>

            <span class="caption">
              <translate>*minimum budget - 100$</translate>
            </span>
          </div>
        </div>

        <div v-if="currentStep === 3" class="content">
          <span class="subtitle">
            <translate>Choose campaign format:</translate>
          </span>

          <div class="tile">
            <div
              v-for="item in formats"
              :key="item.id"
              :class="{ tileItemSelected: format && item.id === format.id }"
              class="tileItem"
              @click="selectFormat(item)"
            >
              <Icon :icon="item.icon" class="tileItem-icon" />

              <div class="d-flex flex-column gap-1">
                <span class="tileItem-title">{{ item.name }}</span>
                <span class="tileItem-description">
                  {{ item.description }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentStep === 4" class="content">
          <span v-if="format.networks.length" class="subtitle">
            <translate>Choose social media platform:</translate>
          </span>

          <div v-if="format.networks.length === 0" class="challenge">
            <span class="subtitle">
              <translate>To create challenge contacts us</translate>
            </span>
            <BaseButton
              :rounded="true"
              :title="$gettext('Message')"
              icon="bxl-telegram"
              @click="handleChallengeBtnClick"
            />
          </div>

          <div class="tile tileNetworks">
            <div
              v-for="item in getNetworksInOrder(format.networks)"
              :key="item.id"
              :class="{
                tileItemSelected: network && item.id === network.id,
                disabled: item.active === false,
              }"
              class="tileItem tileItem-network"
              @click="selectNetwork(item)"
            >
              <Icon
                :icon="getNetworkIcon(item.network)"
                class="tileItem-icon"
              />

              <div class="d-flex flex-column gap-1">
                <span class="tileItem-title">
                  {{ item.network | capitalize }}
                </span>
                <span class="tileItem-description">
                  {{ getNetworkDescription(item.network) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="controls">
        <button
          v-if="currentStep !== 1"
          :disabled="currentStep === 1"
          class="borderlessBtn"
          @click.prevent="goStepBack"
        >
          <Icon class="borderlessBtn-icon" icon="bx:chevron-left" />
          <span class="borderlessBtn-text">
            <translate>Back</translate>
          </span>
        </button>

        <BaseButton
          v-if="currentStep === 1"
          :disabled="!validateEmail() || !phone"
          :title="$gettext('Continue')"
          class="controls-btn"
          @click="handleContinueBtnClick"
        />

        <BaseButton
          v-if="currentStep === 2"
          :disabled="!campaignName || !budget"
          :title="$gettext('Continue')"
          class="controls-btn"
          @click="handleContinueBtnClick"
        />

        <BaseButton
          v-else-if="currentStep === 3"
          :disabled="!format"
          :title="$gettext('Continue')"
          class="controls-btn"
          @click="handleContinueBtnClick"
        />

        <BaseButton
          v-else-if="currentStep === 4 && format.networks.length"
          :disabled="!network"
          :title="$gettext('Create a campaign')"
          class="controls-btn"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.wrapper {
  height: 100%;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
  padding: 20px;

  @include for-phone-only {
    padding: 10px;
  }

  &-top {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    @include bootstrap-large-lower-boundary {
      gap: 30px;
    }
  }
}

.title {
  font-size: 24px;
  line-height: 28px;
  color: $primary-black;
  margin: 0;

  @include for-phone-only {
    font-size: 18px;
  }
}

.subtitle {
  font-size: 16px;
  color: $primary-black;

  @include for-phone-only {
    font-size: 14px;
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &-multiple {
    display: flex;
    gap: 15px;
  }
}

.tile {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @include bootstrap-large-lower-boundary {
    grid-template-columns: repeat(2, 1fr);
  }

  &Networks {
    @include for-phone-only {
      grid-template-columns: 1fr;
    }
  }
}

.tileItem {
  min-width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary-background;
  border-radius: 16px;
  padding: 20px;
  color: $primary-black;
  cursor: pointer;

  @include for-phone-only {
    max-width: 100%;
    min-width: 100%;
    padding: 16px;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &-network {
    @include for-phone-only {
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: center;
      gap: 16px;
      height: 92px;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    color: $brand-color;
  }

  &-image {
    width: 24px;
    height: 24px;
  }

  &-title {
    font-size: 16px;
    line-height: 25px;
  }

  &-description {
    font-size: 12px;
    line-height: 14px;
    opacity: 0.5;
  }

  &Selected {
    background-color: $brand-color;
    color: $primary-white;

    .tileItem-icon {
      color: $primary-white;
    }
  }
}

.challenge {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.controls {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &-btn {
    grid-column: 2 / 3;
  }
}

.borderlessBtn {
  all: unset;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 5px;
  color: $primary-grey;
  cursor: pointer;

  &-icon {
    height: 20px;
    width: 20px;
  }

  &-text {
    font-size: 16px;

    @include for-phone-only {
      font-size: 14px;
    }
  }

  &:disabled {
    opacity: 0.3;
  }
}

.continueBtn {
  width: 280px;
  height: 56px;
  font-size: 16px;
  grid-column: 2 / 3;
  justify-self: flex-end;
}

.caption {
  margin-left: 15px;
}
</style>