import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import ApiService from '@/services/api.service'
import { endpoints } from '@/services/endpoints'

// initial state
const state = () => ({
  indexAdCategories: [],
  indexBlogCategories: [],
  indexGeos: [],
})

// getters
const getters = {
  indexAdCategories: state => state.indexAdCategories,
  indexBlogCategories: state => state.indexBlogCategories,
  indexGeos: state => state.indexGeos,
}

// actions
const actions = {
  async fetchIndexAdCategories({ commit }, query) {
    if (query != 'all') {
      await ApiService.get('api/adv/ad_categories/' + query)
        .then(({ data }) => {
          commit('setIndexAdCategories', data)
        })
        .catch(() => {})
    }
  },
  async fetchIndexBlogCategories({ commit }) {
    await ApiService.get('api/common/interest/')
      .then(({ data }) => {
        commit('setIndexBlogCategories', data)
      })
      .catch(() => {})
  },
  async fetchIndexGeos({ commit }, query) {
    if (query != 'all') {
      await ApiService.get('api/common/geo/' + query)
        .then(({ data }) => {
          commit('setIndexGeos', data)
        })
        .catch(() => {})
    }
  },
  // campaign creation
  getCampaignFormats() {
    return ApiService.get(endpoints.campaign.campaign_formats)
  },
  createCampaign(_, data) {
    return ApiService.post(endpoints.campaign.create_campaign, data)
  },
}

// mutations
const mutations = {
  setIndexAdCategories: (state, adCategories) => {
    state.indexAdCategories = adCategories
  },

  setIndexBlogCategories: (state, blogCategories) => {
    state.indexBlogCategories = blogCategories
  },

  setIndexGeos: (state, geos) => {
    state.indexGeos = geos
  },
}

export default new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
  modules: {},
})
