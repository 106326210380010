<script>
export default {
  name: "BaseStepProgressBar",
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  methods: {
    getStepClass(idx) {
      return {
        reached: idx < this.currentStep,
      };
    },
  },
};
</script>

<template>
  <div class="step-progress">
    <div
      v-for="(step, idx) in steps"
      :key="idx"
      class="step"
      :class="getStepClass(idx)"
    >
      <div class="divider" v-if="idx !== 0" :class="getStepClass(idx)"></div>
      <div class="circle">{{ step }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.step-progress {
  display: flex;
  flex-wrap: nowrap;
  width: min-content;
}

.step {
  display: flex;
  align-items: center;
}

.circle {
  font-size: 14px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $secondary-white-grey;
  border: 1px solid $secondary-white-grey;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  @include for-phone-only {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }
}

.divider {
  min-width: 73px;
  height: 1px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 16px;
  background-color: $secondary-white-grey;
  transition: background-color 0.3s ease;

  @include for-phone-only {
    min-width: 53px;
  }
}

.reached .circle {
  background-color: $brand-color;
  border-color: $brand-color;
  color: $primary-white;
}

.reached .divider {
  background-color: $brand-color;
}
</style>