<script>
import { Icon } from "@iconify/vue2";
import colors from "@/style/colors.scss";

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    colorMode: {
      validator: (value) => {
        return ["primary", "secondary", "tertiary"].includes(value);
      },
      required: false,
      default: "primary",
    },
    customStyle: {
      type: Object,
      required: false,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      colors: colors,
    };
  },
  components: {
    Icon,
  },
  computed: {
    colorClass() {
      switch (this.colorMode) {
        case "primary":
          return "primary";
        case "secondary":
          return "secondary";
        case "tertiary":
          return "tertiary";
        default:
          return "primary";
      }
    },
  },
  emits: ["click"],
};
</script>

<template>
  <button
    :class="{ [colorClass]: true }"
    :style="{
      borderRadius: rounded ? '40px' : '16px',
      ...customStyle,
    }"
    class="button"
    @click="$emit('click')"
  >
    <Icon v-if="icon" :icon="icon" class="icon" />
    <span class="title">{{ title }}</span>
  </button>
</template>

<style lang="scss" scoped>
@import "@/style/colors.scss";
@import "@/style/breakpoints.scss";

.button {
  width: auto;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0px 20px;
  border: none;
  color: $primary-white;
  white-space: nowrap;

  @include for-big-desktop-up {
    height: 48px;
    justify-content: center;
  }

  &:focus,
  &:active,
  &:focus-visible,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
  }

  & .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .icon {
    height: 16px;
    width: 16px;

    @include for-big-desktop-up {
      height: 24px;
      width: 24px;
    }
  }
}

.primary {
  background-color: $brand-color;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: $brand-color-dark;
  }
}

.secondary {
  background-color: $primary-black;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: $secondary-grey;
  }
}

.tertiary {
  background-color: $primary-white;
  color: $primary-black;

  &:not(:disabled):active,
  &:not(:disabled):hover {
    background-color: $primary-lavender-dark;
  }
}
</style>