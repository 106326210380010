<script>
import { v4 as generatedId } from "uuid";

export default {
  name: "CampaignCreationInput",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
  },
  data() {
    return {
      isFocused: false,
      inputId: generatedId(),
    };
  },
  mounted() {
    this.$refs.inputRef.addEventListener("click", () => {
      this.$refs.inputRef.focus();
    });
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<template>
  <div class="inputContainer" :class="{ inputContainerFocused: isFocused }">
    <label :class="{ labelFocused: isFocused || value }" :for="inputId">
      {{ label }}
    </label>

    <input
      :id="inputId"
      :value="value"
      @input="handleInput"
      @focus="onFocus"
      @blur="onBlur"
      :type="type"
      ref="inputRef"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "@/style/breakpoints.scss";
@import "@/style/colors.scss";

.inputContainer {
  min-height: 60px;
  height: 60px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $secondary-white-grey;
  overflow: hidden;
  transition: border-color 0.2s;

  &Focused {
    border-color: $brand-color;
  }

  input {
    all: unset;
    height: 100%;
    width: 100%;
    border: none;
    padding: 15px 15px 0px 15px;
    font-size: 16px;
    color: $primary-black;
  }

  label {
    position: absolute;
    left: 15px;
    transform-origin: left bottom;
    transition: top 0.2s, font-size 0.2s, transform 0.2s ease-out;
    color: $primary-grey;
    margin: 0;
    font-size: 16px;

    &.labelFocused {
      font-size: 14px;
      transform: translateY(-100%) scale(0.8);
    }

    &:hover {
      cursor: text;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
