<template>
  <div>
    <!-- Page loading spinner-->
    <div class="page-loading">
      <div class="page-loading-inner">
        <div class="page-spinner"></div><span><translate>Loading...</translate></span>
      </div>
    </div>
    <main class="page-wrapper">
      <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
      <header class="header navbar navbar-expand-lg navbar-dark navbar-floating navbar-sticky" data-fixed-element>
        <div class="container px-0 px-xl-3">
          <button class="navbar-toggler ms-n2 me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryMenu">
            <span class="navbar-toggler-icon"></span>
          </button>
          <a class="navbar-brand flex-shrink-0 order-lg-1 mx-auto ms-lg-0 pe-lg-2 me-lg-4" href="/">
            <img class="navbar-floating-logo d-none d-lg-block" src="images/logo-light.png" alt="ADVY" width="153">
            <img class="navbar-stuck-logo" src="images/logo-dark.png" alt="ADVY" width="153">
            <img class="d-lg-none" src="images/logo-icon.png" alt="ADVY" width="58">
          </a>
          <div class="d-flex align-items-center order-lg-3 ms-lg-auto">
            <a class="btn btn-dark circle-border" :href="baseUrlCabinet" rel="noopener" target="_blank">
              <i class="bi bi-box-arrow-in-right me-2"></i> <translate>Sign in</translate>
            </a>
          </div>
          <div class="offcanvas offcanvas-collapse order-lg-2" id="primaryMenu">
            <div class="offcanvas-header navbar-shadow">
              <h5 class="mt-1 mb-0"><translate>Menu</translate></h5>
              <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <!-- Menu-->
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="#technology"><translate>Technology</translate></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#results"><translate>Results</translate></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#how"><translate>How does it work?</translate></a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#contact"><translate>Contact</translate></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <!-- Page content-->
      <!-- Intro-->
      <section class="d-flex align-items-center position-relative bg-dark bg-size-cover bg-position-center min-vh-100 overflow-hidden pt-6 pb-lg-5" style="background-image: url(images/bg.jpg);">
        <div class="container-fluid pt-4 pb-5 py-lg-5">
          <div class="row align-items-center py-3">
            <div class="col-xl-6 col-lg-5 d-flex justify-content-end">
              <div class="pt-2 mx-auto mb-5 mb-lg-0 ms-lg-0 me-xl-7 text-center text-lg-start" style="max-width: 495px;">
                <p class="display-1 fw-bold text-light"><translate>Automatic Influence Marketing</translate></p>
                <hr class="hr-light mt-0 mb-3">
                <p class="fs-5 fw-lighter text-light opacity-70"><translate>Bringing together micro bloggers and brands to launching effective advertising campaigns</translate></p>
                <a href="#campaignForm" class="btn btn-secondary v-large-btn"><i class="bi bi-handbag-fill"></i> <translate>Get an offer</translate></a>
              </div>
            </div>
            <div class="col-xl-6 col-lg-7">
              <div class="parallax ms-lg-n5" style="max-width: 920px;">
                <div class="parallax-layer position-relative" data-depth="0.1"><img src="images/layer01.png" alt="Layer"></div>
                <div class="parallax-layer" style="z-index: 1;" data-depth="0.3"><img src="images/layer02.png" alt="Layer"></div>
                <div class="parallax-layer" data-depth="0.2"><img src="images/layer03.png" alt="Layer"></div>
                <div class="parallax-layer" style="z-index: 3;" data-depth="0.2"><img src="images/layer04.png" alt="Layer"></div>
                <div class="parallax-layer" data-depth="0.15"><img src="images/layer05.png" alt="Layer"></div>
                <div class="parallax-layer" style="z-index: 4;" data-depth="0.2"><img src="images/layer06.png" alt="Layer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <!-- WHY-->
      <section class="bg-secondary" id="why">
        <div class="container pt-5 pb-4 py-md-6 py-lg-7">
          <h1 class="text-center mb-3"><translate>Why micro bloggers?</translate></h1>
          <div class="container my-2 my-md-0">
            <div class="row align-items-center">
              <div class="col-md-5 mb-md-0 mb-5 pb-md-0 pb-3"><img class="d-block mx-md-0 mx-auto" src="images/question.png" alt="Illustration"></div>
              <div class="col-lg-6 offset-lg-1 col-md-7 text-md-start text-center">
                <div class="mx-md-0 mx-auto" style="max-width: 500px;">
                  <!-- Media item (Service)-->
                  <div class="d-md-flex align-items-start d-block mb-4 pb-2">
                    <i class="bi bi-check"></i>
                    <div class="ps-md-2">
                      <h5 class="h4 mb-2"><translate>Purity audience</translate></h5>
                      <p class="mb-0"><translate>The entire audience is a close circle of friends, colleagues, acquaintances who trust a particular person.</translate></p>
                    </div>
                  </div>
                  <!-- Media item (Service)-->
                  <div class="d-md-flex align-items-start d-block mb-4 pb-2">
                    <i class="bi bi-check"></i>
                    <div class="ps-md-2">
                      <h5 class="h4 mb-2"><translate>High ER%</translate></h5>
                      <p class="mb-0"><translate>Micro and nano bloggers can have engagement rates of up to 40%</translate></p>
                    </div>
                  </div>
                  <!-- Media item (Service)-->
                  <div class="d-md-flex align-items-start d-block">
                    <i class="bi bi-check"></i>
                    <div class="ps-md-2">
                      <h5 class="h4 mb-2"><translate>Clear content</translate></h5>
                      <p class="mb-0"><translate>The content of micro and nano bloggers bloggers have 2-3 clear themes that the blogger demonstrates in front of the audience.</translate></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Technology-->
      <section class="container pt-5 pb-4 mt-3 mt-md-0 mb-md-3 pb-md-5 pt-md-7" id="technology">
        <h1 class="text-center mb-3"><translate>Technology</translate></h1>
        <p class="mx-auto mb-5 text-muted text-center" style="max-width: 600px;"><translate>ADVY is based on a neural network, that allows you to learn about a blogger not just from the description in the profile header.</translate></p>
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="mx-auto mb-5 text-center" style="max-width: 280px;">
              <img class="mb-3" src="images/tech-1.png" alt="Icon" width="120" hight="150">
              <h3 class="h5"><translate>Analyze</translate></h3>
              <p class="fs-sm mb-0"><translate>A neural network builds a "model" of the blogger based on data and relates it to Audience.</translate></p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="mx-auto mb-5 text-center" style="max-width: 280px;">
              <img class="mb-3" src="images/tech-2.png" alt="Icon" width="120" height="150">
              <h3 class="h5"><translate>Checking</translate></h3>
              <p class="fs-sm mb-0"><translate>Using a "blogger model" we identify fake and boosted accounts.</translate></p>
            </div>
          </div>
          <div class="col-sm-6 col-md-4">
            <div class="mx-auto mb-5 text-center" style="max-width: 280px;">
              <img class="mb-3" src="images/tech-3.png" alt="Icon" width="120" height="150">
              <h3 class="h5"><translate>Ratio</translate></h3>
              <p class="fs-sm mb-0"><translate>From adverts to ads ADVY learns and correlates bloggers' audiences with the brand's target audience brand audiences.</translate></p>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-gradient position-relative pt-6 pb-5 py-sm-6">
        <div class="position-absolute top-0 start-0 w-100 h-100 bg-size-cover" style="background-image: url(images/bg-01.png);"></div>
        <div class="position-relative zindex-5 container py-2">
          <div class="d-flex flex-column align-items-center">
            <div class="pb-5 text-center">
              <h1 class="h1 mb-4 text-light"><translate>ADVY launches ad campaigns with a targeted reach of 500K in a few clicks.</translate></h1>
              <h3 class="h6 pb-2 text-light opacity-70">
                <translate>Search</translate>
                <i class="bi bi-arrow-right-short"></i>
                <translate>Check</translate>
                <i class="bi bi-arrow-right-short"></i>
                <translate>Analysis</translate>
                <i class="bi bi-arrow-right-short"></i>
                <translate>Communication</translate>
                <i class="bi bi-arrow-right-short"></i>
                <translate>Exit control</translate>
                <i class="bi bi-arrow-right-short"></i>
                <translate>Payouts</translate>
                <i class="bi bi-arrow-right-short"></i>
                <translate>Detailed analysis</translate>
              </h3>
            </div>

            <div style="max-width: 800px; width: 100%">
              <CampaignForm />
            </div>
          </div>
        </div>
      </section>
      <section class="bg-secondary py-5 py-md-6 py-lg-7">
        <div class="container py-3 py-lg-4">
          <div class="row align-items-center">
            <div class="col-md-6 mb-5 mb-md-0">
              <div class="mx-auto mx-md-0" style="max-width: 525px;"><img class="d-block" src="images/mobileapp.png" alt="Mobile screens"></div>
            </div>
            <div class="col-md-6 text-center text-md-start">
              <h2 class="h1 mb-4"><translate>Blogger's advertising account</translate></h2>
              <p class="text-muted mb-4"><translate>Our team has created a unique modern mobile application for easy use by bloggers. Manage your campaigns absolutely free!</translate></p>

              <div class="d-flex align-items-center align-items-md-start flex-column nowrap gap-3">
                <a :href="$gettext('https://play.google.com/store/apps/details?id=com.advy.app')" target="_blank">
                  <img src="@/assets/images/google-play.svg"/>
                </a>
                <a :href="$gettext('https://apps.apple.com/app/advy-ai/id1628906343')" target="_blank">
                  <img src="@/assets/images/app-store.svg"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container py-3 py-md-4 py-lg-5 my-3" id="how">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-5 text-center text-md-start mb-5 mb-md-0">
            <h1 class="mb-3"><translate>Promotional content</translate></h1>
            <p class="text-muted mb-4 pb-2"><translate>Only quality and working publications. Running ads is easy, try it now for free!</translate></p>
            <a href="#campaignForm" class="btn btn-lg btn-dark">
              <i class="bi bi-play-fill"></i> <translate>Run Ads Now!</translate>
            </a>
          </div>
          <div class="col-lg-8 col-md-7 bg-position-center bg-repeat-0" style="background-image: url(img/demo/business-consulting/services/bg-shape.svg);">
            <div class="mx-auto" style="max-width: 610px;">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <div class="bg-light shadow-lg-boost rounded-3 mb-grid-gutter text-center text-sm-start">
                    <img class="d-inline-block" src="images/box-post-1.png" alt="Post">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="bg-light shadow-lg-boost rounded-3 mb-grid-gutter text-center text-sm-start">
                    <img class="d-inline-block mb-4 mt-2" src="images/box-post-2.png" alt="Post">
                  </div>  
                  <div class="bg-light shadow-lg-boost rounded-3 mb-grid-gutter text-center text-sm-start">
                    <img class="d-inline-block mb-4 mt-2" src="images/box-post-3.png" alt="Post">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class="container-fluid pt-3 pt-mg-4 pt-lg-5" id="results">
        
        <div class="row justify-content-center pt-5 mb-5">
          <div class="col-lg-8">
            <h1 class="text-center mb-5"><translate>Our cases</translate></h1>
            <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-lg-8">
                      <img src="images/case-1.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="col-lg-4 col-md-4 text-start py-5">
                      <h3 class="mb-3"> <img src="images/growfood.png" width="48px;" height="48px;"> <translate>GrowFood</translate></h3>
                      <p class="text-primary mb-4 pb-2"><translate>Objective: to increase brand awareness, reveal competitive advantages.</translate></p>
                      <!-- Contacts card: Border -->
                      <!--<h4>Results:</h4>-->
                      <div class="card">
                        <div class="card-body">
                          <ul class="list-unstyled mb-0">
                            <li class="d-flex pt-2 pb-3 border-bottom">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>Reach to the target audience:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>382.409</translate></a>
                              </div>
                            </li>
                            <li class="d-flex pt-2 pb-3 border-bottom">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>Number of bloggers:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>120</translate></a>
                              </div>
                            </li>
                            <li class="d-flex pt-2">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>СTR:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>13%</translate></a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-8">
                      <img src="images/case-2.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="col-lg-4 col-md-4 text-start py-5">
                      <h3 class="mb-3"> <img src="images/gorenje.png" width="48px;" height="48px;"><translate>Gorenje</translate></h3>
                      <p class="text-primary mb-4 pb-2"><translate>Objective: to notify users about the brand's new product.</translate></p>
                      <!-- Contacts card: Border -->
                      <!--<h4>Results:</h4>-->
                      <div class="card">
                        <div class="card-body">
                          <ul class="list-unstyled mb-0">
                            <li class="d-flex pt-2 pb-3 border-bottom">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>Reach to the target audience:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>124.509</translate></a>
                              </div>
                            </li>
                            <li class="d-flex pt-2 pb-3 border-bottom">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>Number of bloggers:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>24</translate></a>
                              </div>
                            </li>
                            <li class="d-flex pt-2">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>СTR:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>5,49%</translate></a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-8">
                      <img src="images/case-3.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="col-lg-4 col-md-4 text-start py-5">
                      <h3 class="mb-3"> <img src="images/1fit.png" width="48px;" height="48px;"><translate>1FIT</translate></h3>
                      <p class="text-primary mb-4 pb-2"><translate>Objective: installing the application and activating the subscription using the blogger's promo code.</translate></p>
                      <!-- Contacts card: Border -->
                      <!--<h4>Results:</h4>-->
                      <div class="card">
                        <div class="card-body">
                          <ul class="list-unstyled mb-0">
                            <li class="d-flex pt-2 pb-3 border-bottom">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>Reach to the target audience:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>108.820</translate></a>
                              </div>
                            </li>
                            <li class="d-flex pt-2 pb-3 border-bottom">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>Number of bloggers:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>38</translate></a>
                              </div>
                            </li>
                            <li class="d-flex pt-2">
                              <div class="ps-3">
                                <span class="fs-ms text-muted"><translate>СTR:</translate></span>
                                <a class="d-block nav-link-style lead"><translate>9,14%</translate></a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
              <span class="" aria-hidden="true"><i class="bi bi-arrow-left-circle-fill" style="font-size: 32px;"></i></span>
              <span class="visually-hidden"><translate>Previous</translate></span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
              <span class="" aria-hidden="true"><i class="bi bi-arrow-right-circle-fill" style="font-size: 32px;"></i></span>
              <span class="visually-hidden"><translate>Next</translate></span>
              </button>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 pb-md-6 text-center mb-5">
            <h4 class="text-dark mb-grid-gutter"><translate>Run Successful Campaign Now?</translate></h4>
            <a href="#campaignForm" class="btn btn-lg btn-primary"><i class="bi bi-play-fill"></i> <translate>Yes! Let's Start</translate></a>
          </div>
        </div>
      </section>

      <RequisitesSection />

      <footer class="footer bg-dark pt-5 pt-md-6 pt-lg-5" id="contact">
        <div class="container pt-3 pt-md-0">
          <div class="container pb-2 text-center text-md-start">
            <div class="row pt-md-2 pb-3">
              <div class="col-lg-3 col-md-3 pb-4">
                <a class="d-inline-block mb-1 opacity-50" href="/" style="width: 153px;">
                  <img src="images/logo-footer.png" alt="Advy" style="width: 60%">
                </a>

                <p class="fs-sm text-light opacity-50 mb-0"><span class="text-light opacity-50 me-1"><translate>© All rights reserved.</translate></span><a class="nav-link-style nav-link-light" :href="baseUrlLanding" target="_blank" rel="noopener"></a></p>
              </div>
              <div class="col-md-6 pt-2 pb-4">
                <div class="widget widget-light">
                  <div class="d-lg-flex ">
                    <ul class="me-lg-4">
                      <li><a class="widget-link" :href="'mailto:' + $gettext('info@advy.ai')"><i class="bi bi-envelope-fill"></i> <translate>info@advy.ai</translate></a></li>
                    </ul>
                    <ul class="me-lg-4">
                      <li><a class="widget-link" :href="'mailto:' + $gettext('info@advy.ai')"><i class="bi bi-geo-alt-fill"></i> <translate>UL. SZCZYTOWA 26, lokal 15, 20-562, Lublin</translate></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 pt-2 pb-4">
                <div class="widget widget-light">
                  <a class="btn-social bs-outline bs-light bs-instagram bs-lg me-2 mb-2" target="_blank" :href="$gettext('https://www.instagram.com/advy_ai/')">
                    <i class="bi bi-instagram"></i>
                  </a>
                  <a class="btn-social bs-outline bs-light bs-youtube bs-lg me-2 mb-2" target="_blank" :href="$gettext('https://www.youtube.com/channel/UC4l_39wGWoGsLKSVW7IsXIQ')">
                    <i class="bi bi-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- Back to top button-->
      <a class="btn-scroll-top" href="#top" data-scroll data-fixed-element><span class="btn-scroll-top-tooltip text-muted fs-sm me-2"><translate>Up</translate></span><i class="bi bi-arrow-up-short"></i></a>
    </main>
  </div>
</template>

<style scoped>
  .page-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s .2s ease-in-out;
    transition: all .4s .2s ease-in-out;
    background-color: #FBFBFB;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
  }
  .page-loading.active {
    opacity: 1;
    visibility: visible;
  }
  .page-loading-inner {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .2s ease-in-out;
    transition: opacity .2s ease-in-out;
    opacity: 0;
  }
  .page-loading.active > .page-loading-inner {
    opacity: 1;
  }
  .page-loading-inner > span {
    display: block;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    color: #8352C1;
  }
  .page-spinner {
    display: inline-block;
    width: 2.75rem;
    height: 2.75rem;
    margin-bottom: .75rem;
    vertical-align: text-bottom;
    border: .15em solid #367BF2;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner .75s linear infinite;
    animation: spinner .75s linear infinite;
  }
  @-webkit-keyframes spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>

<script>
  import CampaignForm from "@/components/CampaignForm.vue";
  import RequisitesSection from "@/components/RequisitesSection.vue"

  const EXTERNAL_SCRIPTS_HEAD = [
    "/js/bootstrap.bundle.min.js",
    "/js/parallax.min.js",
    "/js/simplebar.min.js",
    "/js/smooth-scroll.polyfills.min.js"
  ];
  const EXTERNAL_SCRIPTS_BODY = [
    "/js/theme.min.js"
  ];

  export default {
    name: "IndexView",
    components: {
      CampaignForm,
      RequisitesSection,
    },
    data: () => ({
      isLoaded: false,
      countHeadLoaded: 0
    }),
    computed: {
      baseUrlLanding() {
        return (process.env.VUE_APP_BASE_URL_LANDING || "https://advy.ai")
      },
      baseUrlCabinet() {
        return (process.env.VUE_APP_BASE_URL_CABINET || "https://app.advy.ai")
      }
    },
    mounted() {
      this.isLoaded = true;
      var vm = this;
      for (let src of EXTERNAL_SCRIPTS_HEAD) {
        const plugin = document.createElement("script");
        plugin.setAttribute("src", src);
        plugin.async = true;
        plugin.onload = function() {
          vm.incHeadLoaded();
        };
        document.head.appendChild(plugin);
      }
    },
    methods: {
      incHeadLoaded: function() {
        this.countHeadLoaded += 1;
        if (this.countHeadLoaded >= EXTERNAL_SCRIPTS_HEAD.length) {
          this.loadBodyScripts()
        }
      },
      loadBodyScripts: function() {
        for (let src of EXTERNAL_SCRIPTS_BODY) {
          const plugin = document.createElement("script");
          plugin.setAttribute("src", src);
          plugin.async = true;
          document.body.appendChild(plugin);
        }
      }
    }
  };
</script>
