<script>
export default {
  props: {
    requisites: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="requisites">
    <div v-for="item in requisites" :key="item.title" class="item">
      <h4>{{ item.title }}</h4>
      <p>{{ item.text }}</p>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.requisites {
  width: max-content;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.item {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

@media (max-width: 767px) {
  .item {
    grid-template-columns: 1fr 2fr;
  }

  .image-wrapper {
    display: none;
  }
}
</style>